const images = {
  hakkaLogo: require('./logos/hakkaLogo.svg').default,
  logoGray: require('./logos/logoGray.svg').default,
  logoGreen: require('./logos/logoHakkaGreen.svg').default,
  iconHome: require('./icons/iconHome.svg').default,
  iconProduct: require('./icons/iconProduct.svg').default,
  iconGovernance: require('./icons/iconGovernance.svg').default,
  iconReward: require('./icons/iconReward.svg').default,
  iconVesting: require('./icons/iconVesting.svg').default,
  iconFarms: require('./icons/iconFarms.svg').default,
  iconVault: require('./icons/iconVault.svg').default,
  iconChallenge: require('./icons/iconChallenge.svg').default,
  iconYearlyReview: require('./icons/iconYearlyReview.svg').default,
  iconLinkSmall: require('./icons/iconLinkSmall.svg').default,
  iconForum: require('./icons/iconForum.svg').default,
  iconOgImage: require('./logos/ogImage.png').default,
  iconAccount: require('./icons/iconAccount.svg').default,
  iconTrash: require('./icons/iconTrash.svg').default,
  iconChekBoxChecked: require('./icons/checkboxChecked.svg').default,
  iconChekBoxUnchecked: require('./icons/checkboxUnchecked.svg').default,
  iconLock: require('./icons/iconLock.svg').default,
  iconBack: require('./icons/iconBack.svg').default,
  iconWaiting: require('./icons/iconWaiting.svg').default,
  iconWithdrawAvailable: require('./icons/iconWithdrawAvailable.svg').default,
  iconMetamask: require('./icons/iconMetamask.svg').default,
  iconVotingPower: require('./icons/iconPower.svg').default,
  iconBecome: require('./icons/iconBecome.svg').default,
  iconTop: require('./icons/iconTop.svg').default,
  iconClaimableReward: require('./icons/iconClaimableReward.svg').default,
  iconInform: require('./icons/iconInform.svg').default,
  iconStep1: require('./icons/icon-step1.svg').default,
  iconStep2: require('./icons/icon-step2.svg').default,
  iconStep3: require('./icons/icon-step3.svg').default,
  iconIgainLp: require('./icons/icon-igain-lp.svg').default,
  iconSort: require('./icons/iconSort.svg').default,
  iconBug: require('./icons/iconBug.svg').default,
  iconQuestion: require('./icons/iconQuestion.svg').default,
  iconProportion: require('./icons/iconProportion.svg').default,
  iconArrowRight: require('./icons/iconArrowRight.svg').default,
  iconArrowRightWhite: require('./icons/iconWhiteArrowRight.svg').default,
  iconFarmsSmall: require('./icons/iconFarmSmall.svg').default,
  iconWallet: require('./icons/iconWallet.svg').default,
  iconShakkaCoin: require('./icons/iconShakkaCoin.svg').default,
  iconHakkaCoinSmall: require('./icons/iconHakkaCoinSmall.svg').default,
  iconTriangleDown: require('./icons/iconTriangleDown.svg').default,
  iconTriangleUp: require('./icons/iconTriangleUp.svg').default,
  iconMockProfile: require('./icons/iconMockProfile.svg').default,
  iconOat: require('./icons/iconOat.svg').default,
  iconP2eBanner: require('./icons/iconP2eBanner.png').default,
  iconWhatHakkaBanner: require('./icons/iconWhatHakkaBanner.png').default,
  iconHakkaProduct: require('./icons/iconHakkaProduct.png').default,
  iconShitcoinmonBanner: require('./icons/iconShitcoinmonBanner.svg').default,
  iconYearReviewBanner: require('./icons/iconYearReviewBanner.png').default,
  imageHowHakka: require('./icons/imageHowHakka.svg').default,
  iconNoData: require('./icons/iconNoData.svg').default,

  // web applications icon
  iconMedium: require('./icons/iconMedium.svg').default,
  iconMediumLarge: require('./icons/iconMediumLarge.svg').default,
  iconTelegram: require('./icons/iconTelegram.svg').default,
  iconTwitter: require('./icons/iconTwitter.svg').default,
  iconDiscord: require('./icons/iconDiscord.svg').default,
  iconGithub: require('./icons/iconGithub.svg').default,
  hakkaAccount: require('./icons/hakkaAccount.svg').default,
  iconYoutube: require('./icons/iconYoutube.svg').default,
  iconReddit: require('./icons/iconReddit.svg').default,

  // Product icons

  iconBlackhole: require('./icons/iconBlackhole.svg').default,
  icon3FMutual: require('./icons/icon3FMutual.jpg').default,
  iconIgain: require('./icons/iconIgain.svg').default,
  iconIgainIG: require('./icons/iconIgainIG.svg').default,
  iconIgainIRS: require('./icons/iconIgainIRS.svg').default,
  iconHakkaIntel: require('./icons/iconHakkaIntel.svg').default,
  iconDefiHandbook: require('./icons/iconDefiHandbook.jpg').default,
  iconCryptoStructure: require('./icons/iconCryptoStructure.png').default,

  // action icons
  iconDown: require('./icons/iconDown.svg').default,
  iconUp: require('./icons/iconUp.svg').default,
  iconRight: require('./icons/iconRight.svg').default,
  iconRightGrey: require('./icons/iconRightGrey.svg').default,
  iconDelete: require('./icons/iconDelete.svg').default,
  iconDeleteRound: require('./icons/iconDeleteRound.svg').default,
  iconAdd: require('./icons/iconAdd.svg').default,
  iconRedeem: require('./icons/iconRedeem.svg').default,
  iconStaking: require('./icons/iconStaking.svg').default,
  iconVaultArchive: require('./icons/iconVaultArchive.svg').default,
  iconToGovernance: require('./icons/iconToGovernance.svg').default,
  iconRocket: require('./icons/iconRocket.svg').default,
  iconArrowRightGreen: require('./icons/iconArrowRightGreen.svg').default,

  /// home icon
  iconLight: require('./icons/iconLight.svg').default,
  iconForward: require('./icons/iconForward.svg').default,
  iconForwardGray: require('./icons/iconForwardGray.svg').default,
  iconLinkNormal: require('./icons/iconLinkNormal.svg').default,
  iconToken: require('./icons/iconToken.svg').default,
  iconScrollDown: require('./icons/iconScrollDown.svg').default,
  iconIntelligence: require('./icons/iconIntelligence.svg').default,
  iconMutual: require('./icons/iconMutual.png').default,
  iconHarvester: require('./icons/iconHarvester.svg').default,
  iconShitcoinmon: require('./icons/iconShitcoinmon.svg').default,
  imageTokenGrey: require('./icons/imageTokenGrey.svg').default,
  imageAlienGrey: require('./icons/imageAlienGrey.svg').default,
  iconEtherscan: require('./icons/iconEtherscan.png').default,
  iconBSCScan: require('./icons/iconBSCScan.png').default,
  iconForwardGreen: require('./icons/iconForwardGreen.svg').default,
  iconParliament: require('./icons/iconParliament.svg').default,
  iconMoneyBox: require('./icons/iconMoneyBox.svg').default,
  iconUser: require('./icons/iconUser.png').default,
  iconLinkSmallGreen: require('./icons/iconLinkSmallGreen.svg').default,
  iconMenu: require('./icons/iconMenu.svg').default,
  iconSnapshot: require('./icons/iconSnapshot.svg').default,
  iconImmunefi: require('./icons/iconImmunefi.png').default,
  iconHashCloak: require('./icons/iconHashCloak.png').default,
  iconArrowDown: require('./icons/iconArrowDown.svg').default,

  /// icon token
  iconCoingecko: require('./icons/iconCoingecko.png').default,
  iconCoinmarketcap: require('./icons/iconCoinmarketcap.png').default,
  iconInch: require('./icons/iconInch.png').default,
  quickswap: require('./icons/quickswap.png').default,
  iconBalancer: require('./icons/iconBalancer.png').default,
  iconUniswap: require('./icons/iconUniswap.png').default,
  iconPancakeSwap: require('./icons/iconPancakeSwap.png').default,
  iconHakkaCoin: require('./icons/iconHakkaCoin.svg').default,

  // rewards
  icon4Tokens: require('./rewards/icon4Tokens.png').default,
  icon2TokensMkrHakka: require('./rewards/icon2TokensMkrHakka.png').default,
  icon2TokensCompHakka: require('./rewards/icon2TokensCompHakka.png').default,
  iconSealedHakka: require('./rewards/iconSealedHakka.png').default,
  iconBHS: require('./rewards/iconBHS.png').default,
  iconTFT: require('./rewards/iconTFT.png').default,
  iconBSC_BHS: require('./rewards/iconBSC_BHS.png').default,

  /// partners & intergartors
  imageChainlink: require('./icons/imageChainlink.png').default,
  image1inch: require('./icons/image1inch.png').default,
  imageSynthetix: require('./icons/imageSynthetix.png').default,
  imageCompound: require('./icons/compound.png').default,
  imageDelta: require('./icons/delta.png').default,
  imageImtoken: require('./icons/imtoken.png').default,
  imageKyberNetwork: require('./icons/kyberNetwork.png').default,
  imageMaker: require('./icons/maker.png').default,
  imageTrustWallet: require('./icons/trustWallet.png').default,
  imageHashCloak: require('./icons/imageHashCloak.png').default,
  imageBinanceSmartChain: require('./icons/imageBinanceSmartChain.png').default,
  imageBnbChain: require('./icons/imageBnbChain.png').default,
  imagePolygon: require('./icons/imagePolygon.png').default,
  imageAave: require('./icons/imageAave.png').default,
  imageEns: require('./icons/imageEns.png').default,
  imageGelato: require('./icons/imageGelato.png').default,
  imageImmunefiGray: require('./icons/iconImmunefiGray.png').default,
  imageProjectGalaxy: require('./icons/iconProjectGalaxy.png').default,

  // icon chain
  iconEthereum: require('./icons/iconEthereum.png').default,
  iconEthereumDark: require('./icons/icon-logo-ethereum-dark.png').default,
  iconBinanceGray: require('./icons/iconBinanceGray.png').default,
  iconBinanceGold: require('./icons/iconBinanceGold.png').default,
  iconPolygon: require('./icons/iconPolygon.png').default,
  iconEthereumDarkBg: require('./icons/iconEthereumDark.svg').default,
  iconBSCDarkBg: require('./icons/iconBSCDark.svg').default,
  iconPolygonDarkBg: require('./icons/iconPolygonDark.svg').default,
  iconFantom: require('./icons/iconFantom.png').default,

  iconTabEthereum: require('./icons/iconTabEthereum.svg').default,
  iconTabBinance: require('./icons/iconTabBinance.svg').default,
  iconTabPolygon: require('./icons/iconTabPolygon.svg').default,
  iconTabFantom: require('./icons/iconTabFantom.svg').default,

  iconTabEthWithBgColor: require('./icons/iconTabEthWithBgColor.svg').default,
  iconTabBscWithBgColor: require('./icons/iconTabBscWithBgColor.svg').default,
  iconTabPolygonWithBgColor: require('./icons/iconTabPolygonWithBgColor.svg').default,
  iconTabFantomWithBgColor: require('./icons/iconTabFantomWithBgColor.svg').default,
  
  iconTabEthGray: require('./icons/iconTabEthGray.svg').default,
  iconTabBscGray: require('./icons/iconTabBscGray.svg').default,
  iconTabPolygonGray: require('./icons/iconTabPolygonGray.svg').default,
  iconTabFantomGray: require('./icons/iconTabFantomGray.svg').default,

  // icon content
  iconFlower: require('./icons/iconFlower.svg').default,
  iconUnderConstruction: require('./icons/underConstruction.svg').default,
  sakura: require('./icons/sakura.png').default,

  // header background
  headerLogo: require('./logos/headerBg.jpg').default,
  headerLogo3x: require('./logos/headerBg-3x.jpg').default,
  dappHeaderLogo: require('./logos/dappHeaderBg.jpg').default,
  dappHeaderLogo3x: require('./logos/dappHeaderBg-3x.jpg').default,
  blurBall: require('./icons/decorativeBlurBall.png').default,
  iconStakingBanner: require('./icons/iconStakingBanner.svg').default,

  // challenge
  profileLv1: require('./challenge/profile/iconLv1.png').default,
  profileLv2: require('./challenge/profile/iconLv2.png').default,
  mission1_1: require('./challenge/missions/mission1.gif').default,
  mission1_2: require('./challenge/missions/mission2.gif').default,
  mission1_3: require('./challenge/missions/mission3.gif').default,
  mission1_4: require('./challenge/missions/mission4.gif').default,
  mission1_5: require('./challenge/missions/mission5.gif').default,
  mission1_6: require('./challenge/missions/mission6.gif').default,
  mission1_7: require('./challenge/missions/mission7.gif').default,
  mission2_1: require('./challenge/missions/mission8.gif').default,
  mission2_2: require('./challenge/missions/mission9.gif').default,
  mission2_3: require('./challenge/missions/mission10.gif').default,
  mission2_4: require('./challenge/missions/mission11.gif').default,
  mission2_5: require('./challenge/missions/mission12.gif').default,
  mission2_6: require('./challenge/missions/mission13.gif').default,
  iconIntroGraph: require('./challenge/introGraph/iconIntroGraph.svg').default,
  levelUpGif: require('./challenge/levelUp.gif').default,

  // yearly review
  iconReview1: require('./icons/iconReview1.svg').default,
  iconReview2: require('./icons/iconReview2.svg').default,
  iconReview3: require('./icons/iconReview3.svg').default,
  iconReview4: require('./icons/iconReview4.svg').default,
  bgShareCard: require('./icons/bgYearlyReviewShareCard.svg').default,
  iconRankS: require('./icons/iconRankS.svg').default,
  iconRankA: require('./icons/iconRankA.svg').default,
  iconRankB: require('./icons/iconRankB.svg').default,
  iconRankC: require('./icons/iconRankC.svg').default,
  iconYearlyReviewTitle: require('./icons/iconYearlyReviewTitle.svg').default,
};
export default images;
